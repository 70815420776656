<template>
  <el-container>
    <el-aside width="auto">
      <span class="myinput">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>
      </span>

      <span class="mytree">
        <el-tree v-if="loadFinish === true" :highlight-current="highlightCurrent" :data="data" :props="defaultProps"
          @node-click="handleNodeClick" ref="tree" accordion :filter-node-method="filterNode"></el-tree>
      </span>
    </el-aside>
    <el-main>
      <div class="boxes">
        <span v-show="yearData.length === 0 && monthData.length === 0 && pieData.length === 0"><i
            class="el-icon-info"></i>{{this.loadText}}</span>
        <span class="mytab">
          <el-tabs tab-position="left">
            <el-tab-pane label="年统计">
              <div class="box" v-show="yearData.length > 0">
                <div class="block_year">
                  
                  <el-date-picker v-model="dateYear" type="year" :clearable="clearable" :editable="editable"
                    :align="alignFormat" placeholder="请选择年份">
                  </el-date-picker>
                </div>
                <highcharts-component :v-show="yearData.length > 0" :type="yearbartype" :styles="styles"
                  :data="yearData" ref="yearBar"></highcharts-component>
              </div>
            </el-tab-pane>
            <el-tab-pane label="月统计">
              <div class="box" v-show="monthData.length > 0">
                <div class="block_month">
                  
                  <el-date-picker v-model="dateMonth" type="month" :clearable="clearable" :editable="editable"
                    :align="alignFormat" placeholder="请选择月份">
                  </el-date-picker>
                </div>
                <highcharts-component :type="monthbartype" :styles="styles" :data="monthData" ref="monthBar">
                </highcharts-component>
              </div>
            </el-tab-pane>
            <el-tab-pane label="高低温统计">
              <div class="box" v-show="pieData.length > 0">
                <highcharts-component :type="pietype" :styles="styles" :data="pieData" ref="pieChart"></highcharts-component>
                <div class="statistictable">
                    <el-table :data="statisticInfo" style="width: 100%" :border="border" highlight-current-row>
                      <el-table-column align="center" header-align="center" prop="totalhour" label="总运行时间(H)">
                      </el-table-column>

                      <el-table-column align="center" header-align="center" prop="totalmin" label="总运行时间(M)">
                      </el-table-column>

                      <el-table-column align="center" header-align="center" prop="highhour" label="高温运行时间(H)">
                      </el-table-column>

                      <el-table-column align="center" header-align="center" prop="highmin" label="高温运行时间(M)">
                      </el-table-column>

                      <el-table-column align="center" header-align="center" prop="normalhour" label="常温运行时间(H)">
                      </el-table-column>

                      <el-table-column align="center" header-align="center" prop="normalmin" label="常温运行时间(M)">
                      </el-table-column>

                      <el-table-column align="center" header-align="center" prop="lowhour" label="低温运行时间(H)">
                      </el-table-column>

                      <el-table-column align="center" header-align="center" prop="lowmin" label="低温运行时间(M)">
                      </el-table-column>
                    </el-table>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </span>
      </div>
    </el-main>
  </el-container>

</template>

<script>
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3D from 'highcharts/highcharts-3d';
import Highmaps from 'highcharts/modules/map';
HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);
Highmaps(Highcharts);

import HighchartsComponent from '../../src/components/HighchartsComponent.vue'
import { getDeviceInfo, getStatisticYearInfo, getStatisticMonthInfo, getTitleNoList } from '../../axios/data.js'
export default {
  components: {
    HighchartsComponent
  },
  name: "statistic",
  data () {
    return {
      pietype: '3dpie',
      monthbartype: 'monthdrilldown',
      yearbartype: 'yeardrilldown',
      styles: {
        width: 75,
        height: 65,
      },
      dateYear: '',
      dateMonth: '',
      alignFormat: 'right',
      clearable: false,
      editable: false,
      pieData: [],
      pieDataLoadFinish: false,
      yearData: [],
      yearDataLoadFinish: false,
      monthData: [],
      monthDataLoadFinish: false,
      loading: null,
      loadText: '',
      titlenoList: [],
      filterText: '',
      loadFinish: false,
      data: [],
      highlightCurrent: true,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      statisticInfo: [],
      border: true
    }
  },
  watch: {
    dateYear (val) {
      var date = new Date(val)
      this.dateYear = date.toString()
      if (this.titleno !== '')
        this.getYearData(this.titleno)
    },
    dateMonth (val) {
      var date = new Date(val)
      this.dateMonth = date.toString()
      if (this.titleno !== '')
        this.getMonthData(this.titleno)
    },
    filterText (val) {
      //console.log('this.$refs.tree',this.$refs.tree)
      if(this.$refs.tree !== undefined)
      {
         this.$refs.tree.filter(val);
      }
    },
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.styles.width = this.windowwidth >= 1440 ? 70 : 60
      this.styles.height = 60
      this.$refs.yearBar.initChart()
      this.$refs.monthBar.initChart()
      this.$refs.pieChart.initChart()
      this.refreshStyle()
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      this.styles.width = this.windowwidth >= 1440 ? 70 : 60
      this.styles.height = 60
      this.$refs.yearBar.initChart()
      this.$refs.monthBar.initChart()
      this.$refs.pieChart.initChart()
      this.refreshStyle()
    }
  },
  computed: {
    domain () {
      return this.$store.state.tab.domain
    },
    domain443 () {
      return this.$store.state.tab.domain443
    },
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    },
    titleno () {
      return this.$store.state.tab.titleno
    },
    titlelist () {
      return this.$store.state.user.titlelist
    }
  },
  mounted () {
    //console.log("domain", this.domain)
    this.onMounted()
    this.refreshStyle()
  },
  methods: {
    checkStar(titleList) {
        return titleList.indexOf("*") !== -1
    },
    openFullScreen () {
      this.loading = this.$loading({
        lock: true,
        text: this.loadText,
        spinner: 'el-icon-loading',
        customClass: 'create-isLoading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    closeFullScreen () {
      this.loading.close();
    },
    async getYearData (label) {
      this.yearData = []
      var params = new Object()
      params.titleno = label
      this.openFullScreen()
      var date = new Date(this.dateYear)
      //console.log(date.getFullYear())
      try {
        var res = await getStatisticYearInfo(this.domain, params)

        var respText = JSON.parse(res.request.responseText);
        if (respText.status === "true") {
          var info = JSON.parse(respText.message.yearinfo)

          var filter = info.filter(item => item.year === date.getFullYear())
          if (filter.length > 0) {
            var yearInfo = filter[0].info
          }
          for (var item in yearInfo) {
            var obj = new Object()
            obj.name = `${yearInfo[item].month}`
            obj.drilldown = `${yearInfo[item].month}`
            // if(yearInfo[item].monthhour === 0) yearInfo[item].monthhour += parseInt(item) % 2 ? 20 : 10
            // if(yearInfo[item].monthmin === 0) yearInfo[item].monthmin += parseInt(item) % 2 ? 40 : 20
            var hour = yearInfo[item].monthhour + yearInfo[item].monthmin / 60
            // if(hour === 0) hour += parseInt(item)
            obj.y = parseFloat(hour.toFixed(1))
            this.yearData.push(obj)
          }
          this.styles.width = this.windowwidth >= 1440 ? 70 : 60
          this.styles.height = 60
          this.$refs.yearBar.initChart()
          this.$refs.yearBar.moreChart()
          this.closeFullScreen()
        }
      } catch (error) {
        this.$message.warning(error)
        this.closeFullScreen()
      }

    },
    async getMonthData (label) {
      this.monthData = []
      var params = new Object()
      params.titleno = label
      this.openFullScreen()

      var date = new Date(this.dateMonth)
      params.year = date.getFullYear()

      try {
        var res = await getStatisticMonthInfo(this.domain, params)
        //console.log(res)
        this.loadText = '正在向服务器请求数据, 请稍候~~'

        var respText = JSON.parse(res.request.responseText);
        if (respText.status === "true") {
          var info = JSON.parse(respText.message.monthinfo)
          var month = date.getMonth() + 1
          var filter = info.filter(item => item.month === month)

          if (filter.length > 0) {
            var monthInfo = filter[0].info
          }
          for (var item in monthInfo) {
            var obj = new Object()
            obj.name = `${monthInfo[item].day}`
            obj.drilldown = `${monthInfo[item].day}`
            // if(monthInfo[item].dayhour === 0) monthInfo[item].dayhour += parseInt(item) % 2 ? 20 : 10
            // if(monthInfo[item].daymin === 0) monthInfo[item].daymin += parseInt(item) % 2 ? 40 : 20
            var hour = monthInfo[item].dayhour + monthInfo[item].daymin / 60
            // if(hour === 0) hour += parseInt(item)
            obj.y = parseFloat(hour.toFixed(1))
            this.monthData.push(obj)
          }
          this.styles.width = this.windowwidth >= 1440 ? 70 : 60
          this.styles.height = 60
          this.$refs.monthBar.initChart()
          this.$refs.monthBar.moreChart()
          this.closeFullScreen()
        }
      } catch (error) {
        this.closeFullScreen()
        this.$message.warning(error)
      }

    },
    async getStatisticInfo (label) {
      this.pieData = []
      var params = new Object()
      params.titleno = label
      this.openFullScreen()
      try {
        var res = await getDeviceInfo(this.domain, params)
        this.loadText = '正在向服务器请求数据, 请稍候~~'

        var respText = JSON.parse(res.request.responseText);
        if (respText.status === "true") {
          var statisticInfo = JSON.parse(respText.message.statisticinfo)
          this.statisticInfo = []
          this.statisticInfo.push(statisticInfo)
          
          var totalhour = statisticInfo.totalhour * 60 + statisticInfo.totalmin
          var highpercent = 33.3, normalpercent = 33.3, lowpercent = 33.3
          if (totalhour > 0) {
            highpercent = (statisticInfo.highhour * 60 + statisticInfo.highmin) / totalhour * 100
            normalpercent = (statisticInfo.normalhour * 60 + statisticInfo.normalmin) / totalhour * 100
            lowpercent = (statisticInfo.lowhour * 60 + statisticInfo.lowmin) / totalhour * 100
          }
          {
            let obj = new Object()
            obj.name = '高温'
            obj.color = 'red'
            obj.y = highpercent
            obj.dataLabels = {
              color: 'red'
            }
            this.pieData.push(obj)
          }

          {
            let obj = new Object()
            obj.name = '常温'
            obj.color = 'green'
            obj.y = normalpercent
            obj.dataLabels = {
              color: 'green'
            }
            this.pieData.push(obj)
          }

          {
            let obj = new Object()
            obj.name = '低温'
            obj.color = 'orange'
            obj.y = lowpercent
            obj.dataLabels = {
              color: 'orange'
            }
            this.pieData.push(obj)
          }
          // this.pieData.push(['高温', highpercent])
          // this.pieData.push(['常温', normalpercent])
          // this.pieData.push(['低温', lowpercent])  
          this.styles.width = this.windowwidth >= 1440 ? 70 : 60
          this.styles.height = 60
          this.$refs.pieChart.initChart()
          this.$refs.pieChart.moreChart()
          this.closeFullScreen()
        }
      } catch (error) {
        this.$message.warning(error)
        this.closeFullScreen()
      }
    },
    changeLoadText () {
      if (this.yearData.length === 0 && this.monthData.length === 0 && this.pieData.length === 0)
        this.loadText = '当前课题暂无数据,请切换别的课题试试'
    },
    async getTitleList () {
      this.loadText = '正在向服务器请求数据, 请稍候~~'
      this.openFullScreen()
      try {
        const res = await getTitleNoList(this.domain)
        var respText = JSON.parse(res.request.responseText);

        //console.log(respText)
        if (respText.status === "true") {
          var isArray = respText.message.constructor === Array
          if (isArray) {
            for (var i = 0; i < respText.message.length; i++) {
              let isvalid = this.checkStar(this.titlelist)
              if(!isvalid)
              {
                 isvalid = this.titlelist.indexOf(respText.message[i].titleno) !== -1
              }
              if(isvalid)
              {
                 this.titlenoList.push(respText.message[i].titleno)
              }
            }
          }
          else {
            var isObject = respText.message.constructor === Object
            if (isObject) {
              let isvalid = this.checkStar(this.titlelist)
              if(!isvalid)
              {
                 isvalid = this.titlelist.indexOf(respText.message.titleno) !== -1
              }
              if(isvalid)
              {
                 this.titlenoList.push(respText.message.titleno)
              }
            }
          }
        }
        console.log(this.titlenoList)

        for (i = 0; i < this.titlenoList.length; i++) {
          const yearstring = this.titlenoList[i].substring(0, 4)
          const year = parseInt(yearstring)
          if (year >= 2000 && year <= 2100) {
            var isexist = false, j = 0
            for (j = 0; j < this.data.length; j++) {
              if (this.data[j].label === yearstring) {
                isexist = true
                break
              }
            }

            if (!isexist) {
              var obj = {
                label: yearstring,
                year: yearstring,
                children: [{
                  label: this.titlenoList[i],
                  year: yearstring,
                  children: []
                }]
              }
              this.data.push(obj)
            }
            else {
              obj = {
                label: this.titlenoList[i],
                year: yearstring,
                children: []
              }
              this.data[j].children.push(obj)
            }
          }
        }
        this.closeFullScreen()
      } catch (error) {
        this.$message.warning(error)
        this.closeFullScreen()
      }

      this.loadFinish = true
      this.closeFullScreen()
      console.log('data', this.data)
      if(this.titleno === '')
      {
         if (this.data.length > 0) {
            if (this.data[0].children.length > 0) {
              this.$store.commit('updateTitleNo', this.data[0].children[0].label)
              
              await this.getStatisticInfo(this.data[0].children[0].label)
              await this.getYearData(this.data[0].children[0].label)
              await this.getMonthData(this.data[0].children[0].label)
              this.filterText = this.data[0].children[0].label
              
            }
         }
      } else {
         await this.getStatisticInfo(this.titleno)
         await this.getYearData(this.titleno)
         await this.getMonthData(this.titleno)
         this.filterText = this.titleno
      }
    },
    onMounted () {
      var now = new Date()
      this.dateYear = now
      this.dateMonth = now
      this.getTitleList()
      this.changeLoadText()
    },
    filterNode (value, data) {
      //console.log(data)
      if (!value) return true;
      return data.label.indexOf(value) !== -1 || data.year.indexOf(value) !== -1;
    },
    handleNodeClick (data) {
      if (data.label.length === 4) {
        var year = parseInt(data.label)
        if (year >= 2000 && year <= 2100) return
      }
      if (data.label === this.titleno) return;
      //console.log("beforechange", this.titleno)
      console.log("statistic store commit")
      this.$store.commit('updateTitleNo', data.label)
      this.getStatisticInfo(data.label)
      this.getYearData(data.label)
      this.getMonthData(data.label)
      //console.log("afterchange", this.titleno)
      this.filterText = ''
    },
    refreshStyle () {
      var Boxes = document.querySelectorAll('.boxes')
      for (var i = 0; i < Boxes.length; i++) {
        Boxes[i].style.height = 80 + 'vh'
      }
      var statTable = document.querySelector('.statistictable')
      statTable.style.width = this.windowWidth >= 1440 ? 70 + 'vw' : 60 + 'vw'
    },
  }
}
</script>

<style lang='scss' scoped>
.myinput ::v-deep {
  .el-input {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .el-input__inner {
    font-size: 14px;
    color: #333;
    background-color: aqua;
    border: 0.052vw solid red;
  }
}

.page {
  color: #dbe276;
  background-color: #1f1f1f;
  border-radius: 0.26vw;
  margin: 1.042vw;
  padding: 0.521vw 1.042vw;
  width: 10.417vw;
  height: 1.563vw;
  line-height: 1.563vw;
}


.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .statistictable ::v-deep {
    .el-table .el-table__header-wrapper tr th {
      background-color: rgb(18, 47, 92) !important;
      color: rgb(255, 255, 255);
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      font-weight: normal;
    }
    .el-table .el-table__row {
      background-color: rgb(18, 47, 92);
      color: rgb(255, 255, 255);
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      font-weight: normal;
    }

    .el-table .el-table__body td {
      background-color: lightblue !important;
      color: #3A3A3A;
    }
    .el-table .el-table__body tr:hover > td {
      background-color: lightgreen !important;
      color: red;
    }

    .el-table__header tr,
    .el-table__header th {
      height: 2.083vw;
      padding: 0;
    }
    .el-table__body tr,
    .el-table__body td {
      height: 2.083vw;
      padding: 0;
    }

    .el-table {
      margin-top: 0.521vw;
    }

    .el-table th {
      display: table-cell !important;
    }

    .el-table--border::after,
    .el-table--group::after {
      width: 0;
    }
    .el-table::before {
      height: 0;
    }
  }

  .statistictable ::v-deep {
    margin-top: 1.042vw;
  }
}

.block_year,
.block_month {
  margin-top: 0.521vw;
  margin-bottom: 1.563vw;
}
.el-icon-info {
  margin-right: 0.521vw;
  font-size: 2.083vw;
  transform: translateY(0.26vw);
}
                                                                                                  
.el-main {
  padding-top: 0;
  background-color:#1389bc;
}

.el-container {
  height: 100%;
  margin-top: 0.042vw;
  margin-bottom: 1.042vw;
  background-color: #333;
  border: 1px solid #333;
}


.mytree ::v-deep {
  overflow: hidden;
  .el-tree-node {
    position: relative;
    padding-left: 0;
  }
  //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
  .el-tree-node__expand-icon.is-leaf {
    display: none;
  }
  .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .el-tree-node__label {
    width: auto;
    float: right;
    font-family: “Trebuchet MS”, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 10px;
    color: #1f1f1f;
    line-height: 1.302vw;
    letter-spacing: 0.052vw;
    &:hover {
      color: white;
    }
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: pink; //背景色
  }
  .el-tree-node__content {
    &:hover {
      background: lightgreen;
      .el-tree-node__label {
        color: white;
      }
    }
  }

  .el-tree .el-icon-caret-right:before {
    content: "\e723";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -5px;
  }
  .el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "\e722";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -5px;
  }
}

.mytab ::v-deep {
  .el-tabs__item {
    margin-top: 15px;
    padding: -1px 0.521vw;
    
    margin-bottom: 15px;
    // border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 60px;
    line-height: 5;
    font-size: 12px;
    font-weight: 400;
    background-color: aquamarine;
    color: #333;
    text-align: center;
    border-radius: 10px;
  }

  .el-tabs__active-bar {
     background-color: transparent;
}

  .el-tabs__item.is-active {
    color: green; //批改激活表头字体色彩，默认是蓝色
    background-color: aqua;
    border-radius: 10px;
    border-bottom: 2px solid red;
  }

  .el-tabs__item:hover {
    height: 60px;
    text-align: center;
    line-height: 5;
    border-radius: 10px;
    color: orangered; //批改鼠标挪动到表头时候字体色彩，默认淡蓝色
    cursor: pointer; //鼠标挪动到表头时候鼠标款式，默认小手
    background-color: pink;
  }

}
</style>


<style lang="scss">
.create-isLoading {
  .el-loading-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 1.042vw;
    border-radius: 0.208vw;
    width: auto;
    text-align: center;
    position: absolute;

    i {
      color: #eee;
    }

    .el-loading-text {
      color: #dbe276;
      font-size: 0.833vw;
    }
  }
}
</style>
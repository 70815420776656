<template>
  <div class="highcharts-container"></div>
</template>

<script>
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighChartSeriesLabel from 'highcharts/modules/series-label'
HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);
HighChartSeriesLabel(Highcharts)

export default {
  props: ['styles', 'type', 'data'],
  name: 'highcharts',
  data () {
    return {
      chart: null
    }
  },
  methods: {
    initChart () {
      //console.log(this.styles.width);
      //console.log(this.styles.height);
      this.$el.style.width = this.styles.width + 'vw';
      this.$el.style.height = this.styles.height + 'vh';
    },
    moreChart () {
      var options = this.getMoreOptions(this.type);

      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Highcharts.Chart(this.$el, options);
    },
    getMoreOptions (type) {
      if (type === 'yeardrilldown') {
        return {
          chart:
          {
            type: 'column'
          },
          title:
          {
            text: null,
            style: {
              fontFamily: '黑体',
              // fontWeight: 'bold',
              fontSize: '1.042vw',
              color: '#1F1F1F'
            }
          },
          xAxis:
          {
            type: 'category'
          },
          yAxis:
          {
            title: {
              text: '小时',
              margin: 10,
            },
            style: {
              color: "#666666"
            }
          },
          legend:
          {
            enabled: false
          },
          credits: { enabled: false },
          plotOptions:
          {
            series:
            {
              borderWidth: 0,
              dataLabels:
              {
                enabled: true
              },
              animation: {
                defer: 500
              }
            }
          },
          series: [
            {
              name: '时间',
              colorByPoint: true,
              data: this.data
            }]
        }
      }
      else if (type === 'monthdrilldown') {
        return {
          chart:
          {
            type: 'column'
          },
          title:
          {
            text: null,
            style: {
              fontFamily: '黑体',
              // fontWeight: 'bold',
              fontSize: '1.042vw',
              color: '#1F1F1F'
            }
          },
          xAxis:
          {
            type: 'category'
          },
          yAxis:
          {
            title: {
              text: '小时',
              margin: 10,
            },
            style: {
              color: "#666666"
            }
          },
          legend:
          {
            enabled: false
          },
          credits: { enabled: false },
          plotOptions:
          {
            series:
            {
              borderWidth: 0,
              dataLabels:
              {
                enabled: true
              },
              animation: {
                defer: 500
              }
            }
          },
          series: [
            {
              name: '时间',
              colorByPoint: true,
              data: this.data
            }]
        }
      }
      else if (type === '3dpie') {
        return {
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: null,
            style: {
              fontFamily: '黑体',
              // fontWeight: 'bold',
              fontSize: '1.042vw',
              color: '1F1F1F'
            }
          },
          credits: { enabled: false },
          tooltip: {
            useHTML: true,
            //pointFormat: '<div style="color: {series.color}">{series.name}: <b>{point.percentage:.1f} %</b></div>'
            //useHTML: true,
            headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
            pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
              '<td style="text-align: right"><b>{point.percentage:.1f} %</b></td></tr>',
            footerFormat: '</table>',
            valueDecimals: 2
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                style: {
                  fontWeight: "bold",
                  fontFamily: '楷体',
                  fontSize: '0.938vw'
                },
                //connectorShape: 'crookedLine'
              }
            },
            animation: {
              defer: 500
            }
          },
          series: [{
            type: 'pie',
            name: '',
            data: this.data
          }]
        }
      }
    }

  }
}
</script>

<style lang="scss">
.highcharts-container {
  border: 1px solid lightseagreen;
}
</style>
